import './Notification.scss';
import React, {useState} from "react";
import {Alert} from "react-bootstrap";
import {t} from "i18next";
import {Trans} from "react-i18next";

export default function Notification() {

    const [show, setShow] = useState(true);

    return (
        <>{show &&
        <Alert variant="warning" onClose={() => setShow(false)} dismissible>
            <Alert.Heading>{t('notification.title')}</Alert.Heading>
            <p>
                <Trans i18nKey="notification.text" components={[<a href="mailto:support@hedgehogreader.eu"> </a>]}/>
            </p>
            <small>{t('notification.comment')}</small>
        </Alert>
        }
        </>
    )

}