import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import React, {useEffect} from 'react';
import {determineLang, setTitleAndLanguage} from '../../Services/utils';
import Header from '../../Components/Header/Header';
import HedgecheckLogo from '../../Assets/Images/Hedgecheck.png';
import {products} from '../../Services/constants';
import InfoItems from '../../Components/InfoItems/InfoItems';
import Menu from "../../Components/Menu/Menu";
import Family from "../../Components/Family/Family";

export default function HedgeCheck() {

    const pageName = products.hedgecheck;

    const params = useParams();
    const {t} = useTranslation();

    const lang = determineLang(window.history, params.lang);

    useEffect(() => {
        setTitleAndLanguage(t('hedgecheck.title'), lang, "/");
    }, [t, lang]);

    return (<div className="container">
        <div className="container pb-3">
            <Header extended title="hedgecheck.title" pageName={pageName} logo={HedgecheckLogo}
                    slogan={t('hedgecheck.slogan')}/>
            <Menu lang={lang} product={products.hedgecheck}/>
            <Family lang={lang} product={products.hedgehogReader}/>
            <InfoItems lang={lang} product={products.hedgecheck}/>
        </div>
    </div>)
}