import './Flag.scss';
import {ru} from "../../Services/constants";
import React from "react";

export default function Flag(props) {
    return (
        <>
            {props.lng === ru &&
            <span className="ru mr-1">
              <ul className="rings">
                    <li className="blue"/>
                    <li className="blue chain"/>
                    <li className="yellow"/>
                    <li className="yellow chain"/>
                    <li className="black"/>
                    <li className="green"/>
                    <li className="green chain"/>
                    <li className="red"/>
                    <li className="red chain"/>
              </ul>
            </span>
            }
            {props.lng !== ru && <span className={`flag-icon flag-icon-${props.flag} mr-1`}/>}
        </>
    )

}