import AppStoreBadge from '../AppStoreBadge/AppStoreBadge';
import {useTranslation} from 'react-i18next';
import {appstore, products, productsData} from '../../Services/constants';
import QRCodeHGR from '../../Assets/Images/qr-code_hg_reader.png';
import QRCodeHGCHCK from '../../Assets/Images/qr-code_hg_check.png';

export default function Download(props) {

    let qrCode;
    let appUrl;

    switch (props.product) {
        case products.hedgehogReader:
            qrCode = QRCodeHGR;
            appUrl = `${appstore}${productsData.get(products.hedgehogReader).id}`;
            break;
        case products.hedgecheck:
            qrCode = QRCodeHGCHCK;
            appUrl = `${appstore}${productsData.get(products.hedgecheck).id}`;
            break;
        default:
            qrCode = QRCodeHGR;
            appUrl = `${appstore}${productsData.get(products.hedgehogReader).id}`;

    }

    const {t} = useTranslation();
    return (
        <div className='d-none d-md-block'>
            <div className="download-wrapper pb-3 pb-sm-0">
                <img src={qrCode} alt={t('imagesAltTexts.qrCode')}/>
                <div className="pt-3 position-relative">
                    <span className="arrow left">&#8592;</span><span>{t('index.download.install')}</span>
                    <div className="or">{t('index.download.or')}</div>
                    <div className="bottom-link">
                                        <span className="visit">{t('index.download.visit')} <a
                                            href={appUrl}>{t('index.download.page')}</a> </span>
                        <span
                            className="arrow right">&#8594;</span>
                        <AppStoreBadge url={appUrl}/>
                    </div>
                </div>
            </div>
        </div>
    )
}