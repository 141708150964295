import './Button.scss';

export default function Button(props) {

    return(
        <div className={`button ${props.disabled ? 'disabled' : ''} ${props.className ?? ''}`} onClick={props.onClick}>
            {props.text}
        </div>
    )
}
