import Header from '../../Components/Header/Header';
import React, {useEffect, useState} from 'react';
import {
    aboutLinksTypes,
    anna_laurson,
    en,
    menuItemTypes,
    menuLinks,
    products,
    socialNetworksImages
} from '../../Services/constants';
import {useParams} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import {determineLang, processParametrizedUrl} from '../../Services/utils';
import Hedgehogs from '../../Assets/Images/hedgehogs.jpg';
import Email from '../../Assets/Images/email.png';
import Feedback from '../../Assets/Images/feedback.png';
import Facebook from '../../Assets/Images/facebook.png';
import Instagram from '../../Assets/Images/Instagram.png';
import BackLink from '../../Components/BackLink/BackLink';
import Image from '../../Components/Image/Image';
import ClickableImageWithText from '../../Components/ClickableImageWithText/ClickableImageWithText';
import './AboutUs.scss';

export default function AboutUs() {

    const pageName = menuLinks.get(menuItemTypes.about);

    const params = useParams();
    const {t} = useTranslation();

    const [fromParametrizedUrl, setFromParametrizedUrl] = useState(true);

    const images = t('aboutUs.images', {returnObjects: true});

    const lang = determineLang(window.history, params.lang);

    useEffect(() => {
        processParametrizedUrl(fromParametrizedUrl, setFromParametrizedUrl, t('aboutUs.title'), lang, `${pageName}`);
    }, [t, lang, fromParametrizedUrl, pageName]);

    return (
        <div className="container pb-3">
            <Header title="aboutUs.title" pageName={pageName}/>
            <div className="row mb-sm-4">
                <div className="col-12">
                    <p>{t('aboutUs.we')}</p>
                    <Image image={{className: 'image', src: Hedgehogs, alt: 'imagesAltTexts.hedgehogs'}}/>
                    <p align="justify"><i>
                        <Trans i18nKey="aboutUs.thanks" components={[<a href={anna_laurson}> </a>]}/>
                    </i></p>
                </div>
            </div>
            <div className="flex-container mb-4">
                <div className="row mt-4 mb-1">
                    {images.map((item, index) => {
                        const evenRow = index % 2 === 0;
                        let image = item;
                        image.url = socialNetworksImages.get(item.type).url;
                        image.className = 'icon mr-2';
                        switch (item.type) {
                            case aboutLinksTypes.email:
                                image.src = Email;
                                break;
                            case aboutLinksTypes.feedback:
                                image.url = `/feedback/${lang ?? en}`;
                                image.src = Feedback;
                                break;
                            case aboutLinksTypes.facebook:
                                image.src = Facebook;
                                break;
                            case aboutLinksTypes.instagram:
                                image.src = Instagram;
                                break;
                            default:
                        }
                        return (
                            <div className={`col-12 col-md-6 ${evenRow ? 'mb-1' : ''}`} key={index}>
                                <ClickableImageWithText image={image}/>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-2">
                    <BackLink to={`${products.hedgehogReader}/${lang}`}/>
                </div>
            </div>
        </div>
    )
}
