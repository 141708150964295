export const backEndServerAddress = `https://${process.env.REACT_APP_MW_ADDRESS}`;
export const backEndServerPort = process.env.REACT_APP_MW_PORT;
export const githubToken = process.env.GITHUB_TOKEN;
export const messagesRouter = 'messages';
export const ipAddressRouter = 'ipAddresses';
export const genres = 'genres';
export const count = '/count/';

export const products = {
    hedgehogReader: 'hedgehogReader',
    hedgecheck: 'hedgecheck'
};

export const appstore = 'https://apps.apple.com/us/app/id';

export const productsData = new Map([
    [products.hedgehogReader, {
        id: '6443732880', screenshotsTypes: {
            importer: 'importer',
            shelf: 'shelf',
            withImage: 'with-image',
            withImageExpanded: 'with-image-expanded',
            table: 'table',
            tableExpanded: 'table-expanded',
            translate: 'translate',
            translated: 'translated',
            noteAndMenu: 'note-and-menu',
            contents: 'contents',
            notes: 'notes',
            voiceSelection: 'voice-selection',
            readAloud: 'read-aloud',
            mediaControl: 'media-control',
            audioBook: 'audiobook',
            audioBookReading: 'audiobook-reading',
            audioBookSettings: 'audiobook-settings',
            audioBookMediaControl: 'audiobook-media-control',
        }
    }],
    [products.hedgecheck, {
        id: '6480376560', screenshotsTypes: {
            mainScreen: 'main-screen',
            mainEdit: 'main-edit',
            checklist: 'checklist',
            newChecklist: 'new-checklist',
            checklistEdit: 'checklist-edit',
            export: 'export'
        }
    }]
]);

export const email = 'mailto:support@hedgehogreader.eu';
export const facebook_url = 'https://www.facebook.com/HedgehogEbookReader';
export const instagram_url = 'https://www.instagram.com/hedgehogebookreader/';
export const anna_laurson = 'https://www.instagram.com/anna_laurson_art/'
export const privacyPolicyExplanationLink = 'https://policies.google.com/privacy?hl=';

export const screenshotsFolder = 'Screenshots';
export const top_page_url = 'top';

export const en = 'en';
export const ru = 'ru';

export const langMap = new Map([
    ['en', 'gb'],
    ['es', 'es'],
    ['pl', 'pl'],
    ['ua', 'ua'],
    ['ru', 'ol']
]);

export const menuItemTypes = {
    download: 'download',
    features: 'features',
    screenshots: 'screenshots',
    videos: 'videos',
    about: 'about',
    privacy: 'privacy',
    migrationGuide: 'migrationGuide',
    genres: 'genres'
};

export const aboutLinksTypes = {
    email: 'email',
    feedback: 'feedback',
    facebook: 'facebook',
    instagram: 'instagram',
};


export const menuLinks =
    new Map(
        [
            [menuItemTypes.download, 'download'],
            [menuItemTypes.features, 'features'],
            [menuItemTypes.screenshots, 'screenshots'],
            [menuItemTypes.videos, 'videos'],
            [menuItemTypes.about, 'about-us'],
            [menuItemTypes.privacy, 'privacyPolicy'],
            [menuItemTypes.migrationGuide, 'migrationGuide'],
            [menuItemTypes.genres, 'genres']
        ]);

export const videoTypes = {
    iPhoneVertical: 'iPhone-vertical',
    iPadVertical: 'iPad-vertical',
    iPadHorizontal: 'iPad-horizontal',
}

export const videos = [
    {id: '271130317727200', type: videoTypes.iPhoneVertical},
    {id: '164353445635313', type: videoTypes.iPadVertical},
    {id: '437564520741904', type: videoTypes.iPhoneVertical},
    {id: '505112907589673', type: videoTypes.iPadHorizontal},
]

export const dimensions = new Map(
    [
        [videoTypes.iPhoneVertical, {width: '267', height: '476'}],
        [videoTypes.iPadVertical, {width: '380', height: '476'}],
        [videoTypes.iPadHorizontal, {width: '560', height: '314'}],
    ]);

export const socialNetworksImages = new Map(
    [
        [aboutLinksTypes.email, {url: email}],
        [aboutLinksTypes.feedback, {url: aboutLinksTypes.feedback}],
        [aboutLinksTypes.facebook, {url: facebook_url}],
        [aboutLinksTypes.instagram, {url: instagram_url}],
    ]);

export const genresParsing = {
    startTag: '// --- begin tree of genres ---',
    endTag: '// --- end tree of genres ---',
    custom_marker: '// --- hedgehog custom genres ---',
    custom_marker_single: '// --- ns ---',
    conversionStart: '// --- string to genre conversion ---',
    conversionEnd: '// --- insert string->genre above ---',
    marketingVersion: 'MARKETING_VERSION'
}

export const fictionBookUrls = {
    en: 'http://fictionbook.org/index.php/Eng:FictionBook_2.1_genres',
    ru: 'http://fictionbook.org/index.php/%D0%96%D0%B0%D0%BD%D1%80%D1%8B_FictionBook_2.1'
}
