import {Splide, SplideSlide} from '@splidejs/react-splide';
import 'react-gallery-carousel/dist/index.css';
import {useTranslation} from 'react-i18next';
import '@splidejs/react-splide/css';
import './Screenshots.scss';
import {products} from '../../Services/constants';
import Image from "../Image/Image";

export default function Screenshots(props) {
    const {t} = useTranslation();

    let screenshots;

    switch (props.product) {
        case products.hedgehogReader:
            screenshots = t('index.screenshots', {returnObjects: true});
            break;
        case products.hedgecheck:
            screenshots = t('hedgecheck.screenshots', {returnObjects: true});
            break;
        default:
            screenshots = t('index.screenshots', {returnObjects: true});
    }

    return (<div className="screenshots">
        <Splide options={{
            perPage: 3,
            breakpoints: {
                767: {
                    perPage: 1,
                },
            },
        }}>
            {screenshots.map((item, index) => {
                return (
                    <SplideSlide key={index}>
                        <div className="image-wrapper">
                            <Image image={{className: 'screenshot', src: `/images/${props.product}/${props.lang.toUpperCase()}/${item.type}.png`, alt: item.text}}/>
                            <div><i>{item.text}</i></div>
                        </div>
                    </SplideSlide>
                );
            })}
        </Splide>
    </div>)
}