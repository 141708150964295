import Badge from '../../Assets/Images/badge.png';
import Image from '../Image/Image';

export default function AppStoreBadge(props) {

    return (
        <a href={props.url}>
            <Image image={{className: 'mr-2', src: Badge, alt: 'imagesAltTexts.badge'}}/>
        </a>
    )
}