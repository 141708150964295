import {useTranslation} from 'react-i18next';
import {menuLinks, top_page_url} from '../../Services/constants';
import {HashLink} from 'react-router-hash-link';

export default function InfoItem(props) {
    const {t} = useTranslation();
    return (
        <div className={`row ${props.isDownload ? 'd-none d-md-block' : ''}`}>
            <div className="col-12 mb-4">
                <h2 id={menuLinks.get(props.item.type)}>{`${props.item.title}:`}</h2>
                <div className="content-wrapper">
                    {props.children}
                </div>
                <HashLink to={`#${top_page_url}`}>{t('links.topLink')}</HashLink>
            </div>
        </div>
    )
}