import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {determineLang, processParametrizedUrl} from '../../Services/utils';
import {menuItemTypes, menuLinks, products} from '../../Services/constants';
import BackLink from '../../Components/BackLink/BackLink';
import PrivacyPolicyParagraph from '../../Components/PrivacyPolicyParagraph/PrivacyPolicyParagraph';
import Header from "../../Components/Header/Header";

export default function PrivacyPolicy() {

    const params = useParams();
    const {t} = useTranslation();

    const [fromParametrizedUrl, setFromParametrizedUrl] = useState(true);

    const lang = determineLang(window.history, params.lang);

    const product = params.product;

    let title;
    const pageName = menuLinks.get(menuItemTypes.privacy);
    let paragraphs = t('privacyPolicy.paragraphs', {returnObjects: true});

    switch (product) {
        case products.hedgehogReader:
            title = 'privacyPolicy.title';
            break;
        case products.hedgecheck:
            title = 'hedgecheck.privacyPolicy.title';
            paragraphs.splice(1,1);
            break;
        default:
            title = 'privacyPolicy.title';
    }

    useEffect(() => {
        processParametrizedUrl(fromParametrizedUrl, setFromParametrizedUrl, t('privacyPolicy.title').replaceAll('<br/>','').trim(), lang, pageName);
    }, [t, lang, fromParametrizedUrl, pageName])

    return (
        <div className="container">
            <Header title={title} pageName={pageName}/>
            {paragraphs.map((item, index) => {
                return (<PrivacyPolicyParagraph item={item} key={index}/>);
            })}
            <div className="row">
                <div className="col-12 mt-2">
                    <BackLink to={`${product}/${lang}`}/>
                </div>
            </div>
        </div>
    )
}
