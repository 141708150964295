import Header from '../../Components/Header/Header';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {determineLang, processParametrizedUrl} from '../../Services/utils';
import BackLink from '../../Components/BackLink/BackLink';
import './Feedback.scss';
import FeedbackMessage from "../../Components/FeedbackMessage/FeedbackMessage";
import {products} from "../../Services/constants";

export default function Feedback() {

    const pageName = 'feedback';//menuLinks.get(menuItemTypes.about); //TODO: add to constants

    const params = useParams();
    const {t} = useTranslation();

    const [fromParametrizedUrl, setFromParametrizedUrl] = useState(true);

    const lang = determineLang(window.history, params.lang);

    useEffect(() => {
        processParametrizedUrl(fromParametrizedUrl, setFromParametrizedUrl, t('feedback.title'), lang, pageName);
    }, [t, lang, fromParametrizedUrl, pageName]);

    return (
        <div className="container pb-3">
            <Header title={t('feedback.title')} pageName={pageName}/>
            <div className="row">
                <div className="col-12">
                    <FeedbackMessage lang={lang}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-2">
                    <BackLink to={`${products.hedgehogReader}/${lang}`}/>
                </div>
            </div>
        </div>
    )
}
