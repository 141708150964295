import {useTranslation} from 'react-i18next';
import i18n from 'i18next';
import {dimensions, en, videos} from '../../Services/constants';
import Video from '../Video/Video';
import './Videos.scss';

export default function Videos() {
    const {t} = useTranslation();

    return (
        <>
            {i18n.language !== en &&
            <div>
                {t('index.videoSubtitle')}
            </div>
            }
            <div className="videos">
                {videos.map((video, index) => {
                    const dims = dimensions.get(video.type);
                    return (
                        <Video id={video.id} width={dims.width} height={dims.height} key={index} type={video.type}/>
                    );
                })}
            </div>
        </>
    )
}