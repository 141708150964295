import Header from '../../Components/Header/Header';
import {useTranslation} from 'react-i18next';
import Menu from '../../Components/Menu/Menu';
import InfoItems from '../../Components/InfoItems/InfoItems';
import {useParams} from 'react-router-dom';
import {useEffect} from 'react';
import {determineLang, setTitleAndLanguage} from '../../Services/utils';
import Notification from '../../Components/Notification/Notification';
import Family from '../../Components/Family/Family';
import {products} from '../../Services/constants';


export default function View() {

    const params = useParams();
    const {t} = useTranslation();

    const lang = determineLang(window.history, params.lang);

    useEffect(() => {
        if (window.location.pathname === '/') {
            window.history.replaceState('Adjusting url', t('index.title'), `${products.hedgehogReader}/${lang}`);
        }
        setTitleAndLanguage(t('index.title'), lang, `${products.hedgehogReader}/${lang}`);
    }, [t, lang]);

    return (<div className="container">
        <Header extended title="index.header.title" pageName={products.hedgehogReader}/>
        <Menu lang={lang} product={products.hedgehogReader}/>
        <Notification/>
        <Family lang={lang} product={products.hedgecheck}/>
        <InfoItems lang={lang} product={products.hedgehogReader}/>
    </div>)
}