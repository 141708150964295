import React, {useEffect, useState} from 'react';
import i18n from 'i18next';
import './LanguagesSwitch.scss';
import {useTranslation} from "react-i18next";
import {langMap} from '../../Services/constants';
import Dropdown from 'react-bootstrap/Dropdown';
import Flag from "../Flag/Flag";

export default function LanguagesSwitch(props) {

    const {t} = useTranslation();

    const [title, setTitle] = useState(t('title'));

    useEffect(() => {
        document.title = title;
    }, [title]);

    const dropdownItems = [];

    langMap.forEach(function (value, key) {
        dropdownItems.push(
            <Dropdown.Item className="custom" value={key} key={key} active={key === i18n.language} onClick={() => {
                if (key !== i18n.language) {
                    i18n.changeLanguage(key);
                    window.history.pushState({lang: key,}, t(`${props.pageName}.title`), key);
                    setTitle(t(`${props.pageName}.title`).replaceAll('<br/>', '').trim());
                }
            }}>
                <Flag lng={key} flag={value}/>
                {key.toUpperCase()}
            </Dropdown.Item>
        )
    });

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle id="dropdown-basic1" className="custom">
                    <Flag lng={i18n.language}
                          flag={langMap.get(i18n.language)}/> {i18n.language.toUpperCase()} {langMap[i18n.language]}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {dropdownItems}
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}