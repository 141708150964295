import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {determineLang, processParametrizedUrl} from "../../Services/utils";
import Header from "../../Components/Header/Header";
import GenresList from "../../Components/GenresList/GenresList";
import BackLink from "../../Components/BackLink/BackLink";
import {products} from "../../Services/constants";

export default function Genres() {

    const pageName = 'genres'; //TODO: add to constants

    const params = useParams();
    const {t} = useTranslation();

    const [fromParametrizedUrl, setFromParametrizedUrl] = useState(true);

    const lang = determineLang(window.history, params.lang);

    const newGenreName = params.new_genre;

    let menuItems = t('index.menu', {returnObjects: true});

    useEffect(() => {
        processParametrizedUrl(fromParametrizedUrl, setFromParametrizedUrl, menuItems[6]['title'], lang, pageName);
    }, [t, lang, fromParametrizedUrl, pageName, menuItems]);

    return (
        <div className="container pb-3">
            <Header title={menuItems[6]['title']} pageName={pageName}/>
            <div className="row">
                <div className="col-12">
                    <GenresList lang={lang} newGenreName={newGenreName}/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-2">
                    <BackLink to={`${products.hedgehogReader}/${lang}`}/>
                </div>
            </div>
        </div>
    )

}