import {Trans, useTranslation} from "react-i18next";
import {products} from "../../Services/constants";
import React from "react";
import './Features.scss'

export default function Features(props) {
    const {t} = useTranslation();

    let features;

    switch (props.product) {
        case products.hedgehogReader:
            features = t('index.features', {returnObjects: true});
            break;
        case products.hedgecheck:
            features = t('hedgecheck.features', {returnObjects: true});
            break;
        default:
            features = t('index.features', {returnObjects: true});
    }

    return (
        <ul>
            {features.map((item, index) => {
                return (<li key={index}>
                    <Trans i18nKey={item} components={[<div className="sub-item"/>]}/>
                </li>);
            })}
        </ul>
    )
}