import './Video.scss';
import {useCallback, useEffect, useState} from 'react';

const Video = (props) => {

    const calculateDimensions = useCallback(() => {
        const {innerWidth} = window;
        const marginSize = 15 * 2 + 8 * 2;
        const overflow = innerWidth - marginSize < props.width;

        const frameWidth = overflow ? innerWidth - marginSize : props.width;
        const frameHeight = overflow ? Math.round(props.height * frameWidth / props.width) : props.height;

        return {width: frameWidth, height: frameHeight}
    },[props.width, props.height])

    const [frameSize, setFrameSize] = useState(calculateDimensions());

    useEffect(() => {

        function handleResize() {
            setFrameSize(calculateDimensions());
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [calculateDimensions]);

    return (
        <div className="video m-2">
            <iframe
                title={props.id}
                id={props.id}
                className="video-frame"
                src={`https://www.facebook.com/plugins/video.php?height=${frameSize.height}&href=https%3A%2F%2Fwww.facebook.com%2FHedgehogEbookReader%2Fvideos%2F${props.id}%2F&show_text=false&width=${frameSize.width}`}
                width={frameSize.width}
                height={frameSize.height}
                scrolling="no" frameBorder="0"
                allowFullScreen={true}
                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
        </div>
    )
}

export default Video;