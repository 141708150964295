import InfoItem from '../InfoItem/InfoItem';
import {menuItemTypes, products} from '../../Services/constants';
import Screenshots from '../Screenshots/Screenshots';
import Videos from '../Videos/Videos';
import Download from '../Download/Download';
import Features from '../Features/Features';
import './InfoItems.scss';
import {useTranslation} from "react-i18next";

export default function InfoItems(props) {

    const {t} = useTranslation();

    let menuItems = t('index.menu', {returnObjects: true});

    if (props.product === products.hedgecheck) {
        menuItems.splice(3, 1);
        menuItems.splice(5, 1);
    }

    return (
        <>
            {menuItems.map((item, index) => {
                if (!item.newPage) {
                    const isDownload = item.type === menuItemTypes.download;
                    return (<InfoItem item={item} key={index} isDownload={isDownload}>
                        {isDownload &&
                        <Download product={props.product}/>
                        }
                        {item.type === menuItemTypes.features &&
                        <Features product={props.product}/>
                        }
                        {item.type === menuItemTypes.screenshots &&
                        <Screenshots product={props.product} lang={props.lang}/>
                        }
                        {item.type === menuItemTypes.videos &&
                        <Videos/>
                        }
                    </InfoItem>);
                }
                return null;
            })}
        </>
    )
}
