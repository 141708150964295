import React from 'react';
import {top_page_url} from '../../Services/constants';
import '../../Services/i18n';
import {Trans, useTranslation} from 'react-i18next';
import LanguagesSwitch from '../LanguagesSwitch/LanguagesSwitch';
import Logo from '../../Assets/Images/icon167new.png';
import './Header.scss';

export default function Header(props) {

    const {t} = useTranslation();
    return (
        <div className="row mb-sm-4">
            <div className="col-12 header-wrapper">
                {props.extended &&
                <>
                    <div className="logo-wrapper" id={top_page_url}>
                        <img className="logo" src={props.logo ?? Logo} alt=''/>
                    </div>
                </>
                }
                <div className={`headers ${props.extended ? 'extended' : ''}`}>
                    <h1 className="mt-2 mb-2">
                        <Trans i18nKey={props.title}/>
                    </h1>
                    {props.extended &&
                    <div className="d-none d-sm-block">{props.slogan ?? t('index.header.slogan')}</div>
                    }
                </div>
                <div className="d-none d-md-block langs mt-2">
                    <LanguagesSwitch pageName={props.pageName}/>
                </div>
            </div>
            {props.extended &&
            <div className="col-12">
                <div className="d-sm-none mt-1 mb-1 slogan">{props.slogan ?? t('index.header.slogan')}</div>
            </div>
            }
            <div className="col-12">
                <div className="d-md-none mt-3 mb-4">
                    <LanguagesSwitch pageName={props.pageName}/>
                </div>
            </div>
        </div>
    );
}
