import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {isEmpty, isValidEmail} from '../../Services/utils';
import Reaptcha from 'reaptcha';
import Button from '../Button/Button';
import './FeedbackMessage.scss';
import {addMessage, checkIP, checkMiddleWare} from '../../Services/Actions/MessageActions';
import {getUserIP} from "../../Services/Actions/UserActions";
import {en} from "../../Services/constants";
import SendingNotAllowedWarning from "../SendingNotAllowedWarning/SendingNotAllowedWarning";
import Loading from "../Loading/Loading";

const FeedbackMessage = (props) => {

    const captchaRef = useRef(null);

    const {t} = useTranslation();

    const initialMessage = {fromTitle: '', fromAddress: '', text: ''}
    const [message, setMessage] = useState(initialMessage);

    const initialFormState = useMemo(() => {
        return {
            showSentText: false,
            buttonDisabled: true,
            clearDisabled: true,
            validEmail: true,
            verified: false,
            sendingError: false,
            connectingError: false
        }
    }, []);

    const [loaded, setLoaded] = useState(false);
    const [sendingAllowed, setSendingAllowed] = useState(false);

    const [formState, setFormState] = useState(initialFormState);

    const checkMW = useCallback(() => {
        if (!formState.connectingError) {
            checkMiddleWare().catch((err) => {
                console.log(err);
                setFormState({...formState, connectingError: true});
                setLoaded(true);
            });
        }
    }, [formState]);

    useEffect(() => {
        checkMW();

        if (!loaded) {
            getUserIP().then((res) => {
                checkIP(res?.data?.ip_address).then((res) => {
                    setSendingAllowed(res);
                    setLoaded(true);
                });
            });
        }

    }, [checkMW, loaded]);


    const messageEmpty = (message) => {
        return isEmpty(message.text) && isEmpty(message.fromAddress) && isEmpty(message.fromTitle)
    }

    const titleChanged = (event) => {
        let msg = {...message, fromTitle: event.target.value}
        setMessage(msg);
        setFormState({
            ...formState,
            buttonDisabled: disableButton(message.text, message.fromAddress, formState.verified),
            clearDisabled: messageEmpty(msg),
        });
    }

    const addressChanged = (event) => {
        let msg = {...message, fromAddress: event.target.value};
        setMessage(msg);
        setFormState({
            ...formState
        });
    }

    const checkAddress = () => {
        setFormState({
            ...formState,
            validEmail: validEmail(message.fromAddress),
            buttonDisabled: disableButton(message.text, message.fromAddress, formState.verified),
            clearDisabled: messageEmpty(message)
        });
    }

    const textChanged = (event) => {
        let msg = {...message, text: event.target.value};
        setMessage(msg);
        setFormState({
            ...formState,
            buttonDisabled: disableButton(msg.text, message.fromAddress, formState.verified),
            clearDisabled: messageEmpty(msg)
        });
    }

    const validEmail = (email) => {
        return isEmpty(email) || isValidEmail(email);
    }

    const disableButton = (text, address, verified) => {
        return isEmpty(text) || !validEmail(address) || !verified
    }

    const send = () => {
        getUserIP().then((res) => {
            addMessage({...message, lang: props.lang ? props.lang : en, ip: res?.data?.ip_address}).then(() => {
                checkIP(res?.data?.ip_address).then((res) => {
                    setFormState({...initialFormState, showSentText: true});
                    setSendingAllowed(res);
                });
            }).catch((err) => {
                console.log(err);
                setFormState({...formState, sendingError: true});
            });
        })
    }

    const clear = () => {
        setMessage(initialMessage);
        setFormState(initialFormState);
    }

    const onVerify = () => {
        setFormState({
            ...formState,
            buttonDisabled: disableButton(message.text, message.fromAddress, true),
            verified: true
        });
    }

    const onExpire = () => {
        setFormState({
            ...formState,
            verified: false,
            buttonDisabled: true,
        });
    }

    const clearError = () => {
        setFormState({
            ...formState,
            sendingError: false
        });
    }

    return (
        <>
            {!loaded &&
            <Loading/>
            }
            {loaded &&
            <div className="feedback">
                {formState.connectingError &&
                <div className="error global">{t('feedback.connectingError')}</div>
                }
                {!formState.connectingError &&
                <>
                    {formState.sendingError &&
                    <div className="error global">{t('feedback.sendingError')}</div>
                    }
                    {formState.showSentText &&
                    <>
                        <div className="row mb-2">
                            <div className="col-12">
                                {t('feedback.viewTitle')}
                            </div>
                        </div>
                        {(message.fromTitle || message.fromAddress) &&
                        <div className="row mb-2">
                            <div className="col-12">
                                <span className="label"><b>{t('feedback.viewFrom')}</b> </span>
                                <span>{message.fromTitle} {message.fromAddress}</span>
                            </div>
                        </div>
                        }
                        <div className="row mb-2">
                            <div className="col-12">
                                <span className="label"><b>{t('feedback.viewText')}</b> </span>
                                <div className="text-wrapper">
                                    <span className="text">{message.text}</span>
                                </div>
                            </div>
                        </div>
                        {sendingAllowed &&
                        <div className="row mb-2">
                            <div className="col-12">
                                <Button onClick={clear} text={t('feedback.viewSwitchButton')}/>
                            </div>
                        </div>
                        }
                        {!sendingAllowed &&
                        <SendingNotAllowedWarning/>
                        }
                    </>
                    }
                    {!formState.showSentText &&
                    <>
                        {sendingAllowed &&
                        <form>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <div className="label">
                                        <Trans i18nKey="feedback.fromTitle" components={[<i/>]}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12"><input type="text" className="dataInput"
                                                               value={message.fromTitle}
                                                               onChange={titleChanged} onFocus={clearError}/></div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <div className="label">
                                        <Trans i18nKey="feedback.fromAddress" components={[<i/>]}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <input type="text" className="dataInput" value={message.fromAddress}
                                           onChange={addressChanged} onBlur={checkAddress} onFocus={clearError}/>
                                    {!formState.validEmail &&
                                    <div className="error">{t('feedback.addressError')}</div>
                                    }
                                    <div className="small">{t('feedback.addressReminder')}</div>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <Trans i18nKey="feedback.text" components={[<b/>]}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12"><textarea rows="5" className="dataInput"
                                                                  value={message.text}
                                                                  onChange={textChanged} onFocus={clearError}/></div>
                            </div>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <Reaptcha
                                        sitekey={process.env.REACT_APP_SITE_KEY}
                                        ref={captchaRef}
                                        onVerify={onVerify}
                                        onExpire={onExpire}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2">
                                    <Button onClick={send} disabled={formState.buttonDisabled}
                                            text={t('feedback.button')}/>
                                </div>
                                <div className="col-2">
                                    <Button className="cancel" onClick={clear} disabled={formState.clearDisabled}
                                            text={t('feedback.clear')}/>
                                </div>
                            </div>
                        </form>
                        }
                        {!sendingAllowed &&
                        <SendingNotAllowedWarning/>
                        }
                    </>
                    }
                </>
                }
            </div>
            }
        </>
    );
}

export default FeedbackMessage
