import React from 'react';
import {Trans} from 'react-i18next';
import {privacyPolicyExplanationLink} from '../../Services/constants';
import i18n from 'i18next';

export default function PrivacyPolicyParagraph(props) {
    return (
        <div className="row">
            <div className="col-12"><h2>{props.item.header}</h2>
                <div>
                    <Trans i18nKey={props.item.text} components={[<a href={`${privacyPolicyExplanationLink}${i18n.language}`}> </a>]}/>
                </div>
            </div>
        </div>
    )
}