import {useTranslation} from 'react-i18next';
import MenuItem from '../MenuItem/MenuItem';
import './Menu.scss';
import {products} from "../../Services/constants";

export default function Menu(props) {

    const {t} = useTranslation();

    let menuItems = t('index.menu', {returnObjects: true});

    if (props.product === products.hedgecheck) {
        menuItems.splice(3, 1);
        menuItems.splice(5, 1);
    }

    return (
        <div className="flex-container mb-4">
            {menuItems.map((item, index) => {
                return (<MenuItem item={item} key={index} lang={props.lang} product={props.product}/>);
            })}
        </div>
    )
}