import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {products} from "../../Services/constants";
import './Family.scss';
import HGREaderLogo from '../../Assets/Images/icon167new.png';
import HedgecheckLogo from '../../Assets/Images/Hedgecheck.png';
import React from "react";

export default function Family(props) {
    const {t} = useTranslation();

    let title;
    let thumbSrc;
    let familyTitle;

    switch (props.product) {
        case products.hedgehogReader:
            title = t('index.title');
            familyTitle = t('index.familyTitle');
            thumbSrc = HGREaderLogo;
            break;
        case products.hedgecheck:
            title = t('hedgecheck.title');
            familyTitle = t('hedgecheck.familyTitle');
            thumbSrc = HedgecheckLogo;
            break;
        default:
    }

    return (<div className="link">
        <div className="font-italic d-inline-block p-1 framed"><span>{familyTitle}</span>
            <Link to={`/${props.product}/${props.lang}`}><img className="thumb" src={thumbSrc} alt=''/><b>{title}</b></Link></div>
    </div>);
}