import Header from '../../Components/Header/Header';
import React, {useEffect, useState} from 'react';
import {
    menuItemTypes,
    menuLinks, products
} from '../../Services/constants';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {determineLang, processParametrizedUrl} from '../../Services/utils';
import BackLink from '../../Components/BackLink/BackLink';
import './MigrationGuide.scss';
import Image from "../../Components/Image/Image";
import {Splide, SplideSlide} from "@splidejs/react-splide";

export default function MigrationGuide() {

    const pageName = menuLinks.get(menuItemTypes.migrationGuide);

    const params = useParams();
    const {t} = useTranslation();

    const [fromParametrizedUrl, setFromParametrizedUrl] = useState(true);

    const lang = determineLang(window.history, params.lang);

    useEffect(() => {
        processParametrizedUrl(fromParametrizedUrl, setFromParametrizedUrl, t('migrationGuide.title'), lang, pageName);
    }, [t, lang, fromParametrizedUrl, pageName]);

    function importAll(r) {
        const imagesMap = new Map();

        r.keys().forEach((item, index) => {
            /*if (item.includes(`/${i18n.language.toUpperCase()}/`)) { //temp fix, TODO: use webpack
                const type = item.slice(
                    item.lastIndexOf('/') + 1,
                    item.lastIndexOf('.'),
                );*/
            imagesMap.set(index, r(item));
            //}
        });
        return imagesMap;
    }

    const images = importAll(require.context('../../Assets/Migration/', true, /\.(png|jpe?g|svg)$/));

    const steps = t('migrationGuide.steps', {returnObjects: true});

    return (
        <div className="container pb-3">
            <Header title="migrationGuide.title" pageName={pageName}/>
            <div className="row mb-sm-4">
                <div className="col-12">
                    <h2>{t('migrationGuide.descr')}:</h2>
                    <div className="screenshots migration">
                        <Splide options={{
                            perPage: 3,
                            breakpoints: {
                                767: {
                                    perPage: 1,
                                },
                            },
                        }}>
                            {steps.map((item, index) => {
                                return (
                                    <SplideSlide key={index}>
                                        <div className="image-wrapper">
                                            <Image
                                                image={{
                                                    className: 'screenshot',
                                                    src: images.get(index),
                                                    alt: item.text
                                                }}/>
                                            <div><i><b>{index + 1}</b>: <span className="pre">{item.pre}</span>: {item.text}</i></div>
                                        </div>
                                    </SplideSlide>
                                );
                            })}
                        </Splide>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 mt-2">
                    <BackLink to={`${products.hedgehogReader}/${lang}`}/>
                </div>
            </div>
        </div>
    )
}
