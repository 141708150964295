import Image from '../Image/Image';
import React from 'react';
import {Link} from "react-router-dom";

export default function ClickableImageWithText(props) {
    return (

        <Link to={props.image.url}>
            <Image image={props.image}/><span>{props.image.text}</span>
        </Link>
    )
}