import {backEndServerAddress, backEndServerPort, count, ipAddressRouter, messagesRouter} from '../constants';

export async function checkMiddleWare() {
    try {
        const response = await fetch(`${backEndServerAddress}:${backEndServerPort}`);
        await response;
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function addMessage(message) {
    let requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            text: message.text,
            sender: message.fromTitle,
            email: message.fromAddress,
            lang: message.lang,
            ip: message.ip
        })
    };
    try {
        const response = await fetch(`${backEndServerAddress}:${backEndServerPort}/${messagesRouter}`, requestOptions);
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export async function checkIP(ip4Address) {
    const str = `${backEndServerAddress}:${backEndServerPort}/${ipAddressRouter}${count}${ip4Address}`;
    try {
        const response = await fetch(str);
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}
