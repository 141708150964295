import {appstore, menuItemTypes, menuLinks, productsData} from '../../Services/constants';
import AppStoreBadge from '../AppStoreBadge/AppStoreBadge';
import {Link} from "react-router-dom";

export default function MenuItem(props) {

    const isDownload = props && props.item.type === menuItemTypes.download;

    const itemContent = (item) => {
        const lang_end = props.lang ? `/${props.lang}` : '/en';
        const url = `${item.newPage ? '' : '#'}${menuLinks.get(item.type)}${item.type === menuItemTypes.privacy ? `/${props.product}` : ''}${item.newPage ? lang_end : ''}`;
        return item.newPage ? <b><Link to={`/${url}`}>{item.title}</Link></b> : <b><a href={url}>{item.title}</a></b>
    }

    return (
        <>
            {isDownload &&
            <div className="mb-3 mb-sm-0">
                <span className="d-none d-md-block">{itemContent(props.item)}</span>
                <span className="d-md-none">
 		            <AppStoreBadge url={`${appstore}${productsData.get(props.product).id}`}/>
                 </span>
            </div>
            }
            {!isDownload &&
            <div>{itemContent(props.item)}</div>
            }
        </>
    )
}